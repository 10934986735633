<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /  
            <!-- <router-link :to="{name: 'admin-master' }" class="root">
               Librarian
            </router-link> -->
            <span class="child">Issue Book Report</span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Issue Book Report</v-card-title>
            <v-data-table v-model="selected" :headers="headers" :items="bookList1" item-key="name" class="elevation-1 mt-5" :search="search" @current-items="getFiltered">
                <v-spacer></v-spacer>
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field>
                        <download-excel class="ml-5" :data="filterArr" worksheet="bookItemlist" name="BookItem.xls">
                            <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn>
                        </download-excel>
                    </v-toolbar>

                    <tr class="t-row">
                        <div>
                            <th class="d-flex">
                                <span v-for="header in headers" :key="header.text">
                                <span v-if="filters.hasOwnProperty(header.value)">
                                    <!-- <span v-if="header.text == 'ISBN' || header.text == 'Title'">
                                        <v-text-field outlined dense class="m-2" :label="header.text" v-model="filters[header.value]" placeholder=" " :disabled="!filters.hasOwnProperty(header.value)"> </v-text-field>
                                    </span>
                                    <span v-else> -->
                                        <v-autocomplete class="m-2" flat hide-details small dense outlined :label="header.text" multiple clearable :items="columnValueList(header.value)" v-model="filters[header.value]"></v-autocomplete>
                                    </span>
                                <!-- </span> -->
                                </span>
                            <!-- <v-autocomplete class="m-2" @change="searchByAuthor" flat hide-details small dense outlined label="Authors" multiple clearable :items="authorsList" v-model="selected_author" item-text="name" item-value="id"></v-autocomplete> -->
                            </th>
                        </div>
                    </tr>
                </template>
                <template v-slot:item.no_copies="{ item }">
                        <router-link class="bookItem" :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                           {{item.copies}}
                        </router-link>
                    </template>
                    <template v-slot:item.no_available_copies="{ item }">
                        <router-link  class="bookItem"  :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                           {{item.available}}
                        </router-link>
                    </template>
                <template v-slot:item.authors="{ item }">
                    <span v-for="author in item.authors" :key="author">{{author}}<br /></span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            selected_author:[],
            snackbar_msg: "",

            snackbar: false,
            pagination: {
                sortBy: "name",
            },
            dialog: true,
            selected: [],
            authorsList:[],
            headers: [
                {
                    text: "Sr No",
                    value: "srno",
                },
                { text: "ISBN", value: "isbn" },
                { text: "Accession No", value: "a_no" },
                { text: "Title", value: "title" },
                { text: "Edition", value: "edition" },
                //   { text: 'Book Format', value: 'bookFormat' },
                { text: "Book Type", value: "bookType" },
                { text: "Authors", value: "authors" },
                { text: "Category", value: "category" },
                { text: "Publisher", value: "publisher" },
                { text: "Name", value: "borrower_name" }, 
                { text: "No of Copies", value: "no_copies" },
                { text: "Available Copies", value: "no_available_copies" },
            ],
            search: "",
            filters: {
                // srno:[],
                isbn: [],
                title: [],
                // edition: [],
                //    bookFormat: [],
                category: [],
                publisher: [],
                authors: [],
                //  copies: [],
                //   bookType: [],
                //   available:[]
            },
            bookList: [],
            filterArr: [],
        }),
        computed: {
            bookList1() {
                return this.bookList.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                    });
                });
            },
        },

        mounted() {
            this.onLoad();
        },
        methods: {
            onlyUnique(value, index, self) {
                if(value != null && value != "" && value)
                    return self.indexOf(value) === index;
            },

            columnValueList(val) {
                var data = this.bookList.map((d) => d[val]);
                data = data.filter(this.onlyUnique);
                return data
            },
            
            getFiltered(e) {
                console.log(e);
                if(e?.length == 0)
                {
                    this.showSnackbar("#b71c1c", "No Record matching with your input/ filter"); 
                }
                this.filterArr = e;
            },
            // toggleAll() {
            //     if (this.selected.length) this.selected = [];
            //     else this.selected = this.bookList.slice();
            // },
            // changeSort(column) {
            //     if (this.pagination.sortBy === column) {
            //         this.pagination.descending = !this.pagination.descending;
            //     } else {
            //         this.pagination.sortBy = column;
            //         this.pagination.descending = false;
            //     }
            // },
            // columnValueList(val) {
            //   return this.bookList.map(d => d[val])
            // },

            onLoad() {
                this.overlay = true;
                axios
                    .get("/Report/getAllBook")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.bookList = res.data.bookList;
                            this.authorsList = res.data.authorsList
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            searchByAuthor(){
                console.log('selected_author',this.selected_author);
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

        },
    };
</script>
<style scoped>
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .bookItem{
        text-decoration: underline !important;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
</style>
